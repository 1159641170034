@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

@layer base {
  body {
    font-family: 'Inter';
    margin: 0 auto;
    color: #343A40;
  }

  .text-sky-950 {
    color:#0A243F
  }

  .grid-container {
    overflow-x: scroll;
    width: 100%;
    scrollbar-width: thin;
    scrollbar-color: rgba(156, 163, 175, 0.5) transparent;
  }

  .g-recaptcha {
    transform:scale(1);
    transform-origin:0 0;
    background:#F9F9F9;
    border:1px solid #F9F9F9;
    padding:6px;
  }

  /* tooltips lite style */
  .target-tooltip-lite {
    text-decoration: underline;
    cursor: pointer;
  }
  .react-tooltip-lite {
    background: white;
    color: black;
    font-size: 12px;
    box-shadow: 1px 1px 2px 1px rgba(0, 0, 0, 0.1);
    border-radius: 4px
  
  }
  
  /* tooltups lite style */

  .slick-dots li {
    margin:-4px !important;
  }
  .autocomplete-active {
    background: #f7f7f7;
  }
  .active {
    color: #1d4ed8;
  }
  .body_hide_scroll {
    height: 100%;
    overflow-y: hidden;
  }
  html {
    scroll-behavior: smooth;
  }

  ::-webkit-scrollbar {
    width: 0.5rem;
    height: 0rem;
  }
  
  ::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 1rem;
  }
  
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  .no-scrollbar {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }
  
  p > a {
    font-family: 'Inter';
    color: #0366d6;
  }

  .rb-tabs-header {
    white-space: nowrap;
    overflow: scroll;
  }
  
  .react-datepicker-wrapper {
  width: 100%;
  }

  #nprogress .bar {
  background: #286DCC !important;
  }
  
  .form-control {
  margin: 1rem 0
  }

  .form-control-sm {
    margin: 0.5rem 0
  }
  
  .fit-height {
    height: min-content;
  }
  
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  
  input[type=number] {
    -moz-appearance: textfield;
  }
  
  .hoverBlue:hover {
    color: #286DCC;
  }
  
  .tinyScrollbar {
    scrollbar-width: thin;
  }
  
  .tinyScrollbar::-webkit-scrollbar {
    width: 5px;
  }
  
  .tinyScrollbar::-webkit-scrollbar-thumb {
    background: #aaaaaa;
    border-radius: 5px;
  }
  
  .tinyScrollbar::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #e5e7eb; 
    border-radius: 10px;
  }
  
  .tinyScrollbar::-webkit-scrollbar-thumb:hover {
    background: #555555; 
    border-radius: 5px;
  }
  
  .fc-event-title-container {
    padding: 0.5rem
  }

  .border-gradient {
    border-image-slice: 1;
    border-width: 2px;
    border-bottom:0;
    border-left:0;
    border-right:0;
  }

  .border-gradient-white {
    border-image-source: linear-gradient(to right, transparent, white, transparent);
  }
  
  :focus{
    outline: none;
  }
  
  table {
    border-collapse: separate;
    border-spacing: 0;
  }
  
  table tr th:first-child {
    border-left: 1px solid #e0e0e0;
  }
  
  table tr th:last-child {
    border-right: 1px solid #e0e0e0;
  }
  
  table tr td:first-child {
    border-left: 1px solid #e0e0e0;
  }
  
  table tr td:last-child {
    border-right: 1px solid #e0e0e0;
  }
  
  table th {
    background: #F5F5F5;
    color: #1F2937 !important;
    border-top: 1px solid #e0e0e0;
  }
  
  /* top-left border-radius */
  table tr:first-child th:first-child {
    border-top-left-radius: 0.25rem;
  }
  
  /* top-right border-radius */
  table tr:first-child th:last-child {
    border-top-right-radius: 0.25rem;
  }
  
  /* bottom-left border-radius */
  table tr:last-child td:first-child {
    border-bottom-left-radius: 0.25rem;
  }
  
  /* bottom-right border-radius */
  table tr:last-child td:last-child {
    border-bottom-right-radius: 0.25rem;
  }

  .__react_component_tooltip {
    padding: 1rem !important;
    max-width: 350px;
  }
  
  .__react_component_tooltip.show {
    opacity: 1 !important;
  }
  
  .fc-col-header-cell {
    background-color: white !important;
    border: 0 !important;
    border-bottom: 1px solid #d1d5db !important;
  }
  
  .fc-prev-button {
    margin-right: 1rem !important;
  }
  
  .input:disabled {
    background-color: rgba(154, 187, 232, 0.2) !important;
  }

  .carousel-fit-admin {
    width: 75vw
  }
  
  @media only screen and (max-width: 599px) {
    .fc-media-screen {
      height: 505px;
    }

    .qcw-trigger-btn.svelte-noomlu.svelte-noomlu {
      right: 20px !important;
    }

    .qcw-trigger-btn {
      bottom: 5rem !important;
      right: 1rem !important;
    }
    
    .carousel-fit-admin {
      width: 100vw
    }
  }

  @media (min-width: 600px) and (max-width: 768px) {
    .fc-media-screen {
      height: 505px;
    }

    .qcw-trigger-btn.svelte-noomlu.svelte-noomlu {
      right: 20px !important;
    }

    .qcw-trigger-btn {
      bottom: 5.5rem !important;
      right: 1rem !important;
    }
    
    .carousel-fit-admin {
      width: 100vw
    }
  }

  .ms-slider {
    display: inline-block;
    height: 3em;
    overflow: hidden;
    vertical-align: middle;
    mask-image: linear-gradient(transparent, white, white, white, transparent);
    mask-type: luminance;
    mask-mode: alpha;
  }
  .ms-slider__words {
    display: inline-block;
    margin: 0;
    padding: 0;
    list-style: none;
    animation-name: wordSlider;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
    animation-duration: 7s;
  }
  .ms-slider__word {
    display: block;
    line-height: 1.3em;
    text-align: left;
  }
  
  
  @keyframes wordSlider {
    0%, 27% {
      transform: translateY(-75%);
    }
    33%, 60% {
      transform: translateY(-50%);
    }
    66%, 93% {
      transform: translateY(-25%);
    }
    100% {
      transform: translateY(0%);
    }
  }
}

.training-company {
  width: 12.5rem;
  margin-left: -1.5rem;
}

#EZDrawer #EZDrawer__container {
  overflow-y: scroll;
}

.post-content h1, .post-content h2, .post-content h3, .post-content h4, .post-content h5, .post-content h6 {
  padding-top: 10px;
  margin-bottom: 40px;
}

.post-content h2 {
  font-size: 2.25rem;
  font-weight: bold;
}

.post-content h3 {
  font-size: 1.75rem;
  font-weight: bold;
}

.post-content p {
  letter-spacing: .3px;
  line-height: 1.6;
  font-size: 1.25rem;
  margin-bottom: 30px;
}

/* KG Button Blog */
.kg-button-card {
  width: 100%;
  justify-content: center;
}

.kg-button-card, .kg-button-card a.kg-btn {
  display: flex;
  position: static;
  align-items: center;
}

.kg-button-card, .kg-button-card * {
  box-sizing: border-box;
}

.kg-button-card a.kg-btn-accent {
  background-color: #286DCC;
  color: #fff;
}

.kg-button-card a.kg-btn {
  padding: 0 1.2em;
  height: 2.4em;
  line-height: 1em;
  font-family: -apple-system,BlinkMacSystemFont,"Segoe UI","Roboto","Oxygen","Ubuntu","Cantarell","Fira Sans","Droid Sans","Helvetica Neue",sans-serif;
  font-size: .95em;
  font-weight: 600;
  text-decoration: none;
  border-radius: 5px;
  transition: opacity .2s ease-in-out;
}

.kg-button-card, .kg-button-card a.kg-btn {
  display: flex;
  position: static;
  align-items: center;
}

.grid-indigo {
  background-image: radial-gradient(#ffffff43 2px, transparent 2px);
  background-size: 16px 16px;
}

.grid-primary {
  background-image: radial-gradient(#286DCC43 2px, transparent 2px);
  background-size: 16px 16px;
}

ol {
  display: block;
  list-style-type: decimal;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 0px;
}

ul {
  display: block;
  list-style-type: disc;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 0px;
}

.markdown-reader p {
  margin-bottom: 8px;
}

.markdown-reader ul {
  margin-block-start: 0px;
  margin-block-end: 0px;
  padding-inline-start: 1.25em;
}

.markdown-reader ol {
  margin-block-start: 0px;
  margin-block-end: 0px;
  padding-inline-start: 1.25em;
}

@media only screen and (max-width: 600px) {
  .markdown-reader ul {
    margin-block-start: 0px;
    margin-block-end: 0px;
    padding-inline-start: 1.1em;
  }
}

.text-decoration-inherit {
  text-decoration: inherit !important;
  text-decoration-color: white !important;
}

/* Custom radio buttons */
.custom-radio {
  display: none;
}

.custom-radio + label {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border-radius: 4px;
  background-color: #f8f8f8;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
  flex-shrink: 0;
  flex-grow: 0;
}

.custom-radio:checked + label {
  background-color: #286DCC;
  color: white;
}

.qcw-trigger-btn {
  z-index: 100 !important;
}

.qcw-chat-wrapper .qcw-header {
  background: #286DCC !important;
}

.qcw-header .qcw-header-info .qcw-user-display-name {
  color: white !important;
}

.qcw-header .qcw-header-info .qcw-user-status {
  color: white !important;
}

.qismo-login-form__header {
  background: #286DCC !important;
}
.qcw-trigger-btn,
.qcw-cs-trigger-button {
  background: #286DCC !important;
}

.qismo-login-btn {
  background: #286DCC !important;
}

.qismo-input .icon {
  background: rgba(40, 109, 204, 0.2) !important;
}

.qismo-contact-icon {
  fill: #286DCC !important;
}
.qismo-email-icon {
  fill: #286DCC !important;
}